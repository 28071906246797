import { Box } from "@mui/material";
import { useEffect, useState, useRef } from "react";
import { CompoundGraph } from "./CompoundGraph";
import { FBSlider } from "../FBSlider";
import SavingsOutlinedIcon from "@mui/icons-material/SavingsOutlined";
import ScheduleOutlinedIcon from "@mui/icons-material/ScheduleOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import TrendingUpOutlinedIcon from "@mui/icons-material/TrendingUpOutlined";
import { calculateCompoundGrowth } from "../../utils/calculations";
import { formatCurrency } from "../../utils/formatters";
import { SummaryBox } from "./SummaryBox";
import { useFormLogger } from "../../hooks/useFormLogger";
import { useSearchParams } from "react-router-dom";

export function CompoundCalculator() {
  const [searchParams] = useSearchParams();

  const INITIAL_VALUES = {
    startValue: 0,
    deposit: 2500,
    interest: 7.0,
    totalYears: 25,
  };

  const [startValue, setStartValue] = useState<number>(Number(searchParams.get('startValue')) || INITIAL_VALUES.startValue);
  const [deposit, setDeposit] = useState<number>(Number(searchParams.get('deposit')) || INITIAL_VALUES.deposit);
  const [interest, setInterest] = useState<number>(Number(searchParams.get('interest')) || INITIAL_VALUES.interest);
  const [totalYears, setTotalYears] = useState<number>(Number(searchParams.get('totalYears')) || INITIAL_VALUES.totalYears);

  const [calculationValues, setCalculationValues] = useState({
    startValue,
    deposit,
    interest,
    totalYears,
  });

  const formValues = {
    startValue,
    deposit,
    interest,
    totalYears
  };

  useFormLogger({
    formName: 'compound_calculator',
    values: formValues
  });

  useEffect(() => {
    const handler = setTimeout(() => {
      setCalculationValues(formValues);
    }, 300);
    return () => clearTimeout(handler);
  }, [startValue, deposit, interest, totalYears]);

  const results = calculateCompoundGrowth(
    calculationValues.startValue,
    calculationValues.deposit,
    calculationValues.interest,
    calculationValues.totalYears
  );

  const lastResult = results[results.length - 1];
  const finalValue = lastResult.currentValue;
  const totalDeposit = lastResult.totalDeposits;

  const stateParams = Object.entries({
    startValue,
    deposit,
    interest,
    totalYears,
  }).reduce((acc, [key, value]) => {
    if (value !== INITIAL_VALUES[key as keyof typeof INITIAL_VALUES]) {
      acc[key] = value;
    }
    return acc;
  }, {} as Record<string, number>);

  return (
    <Box
      sx={{
        display: "flex",
        my: 2,
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: 1,
          }}
        >
          <Box
            sx={{
              flex: { xs: "1", md: "0 0 400px" },
              display: "flex",
              flexDirection: "column",
            }}
          >
            <FBSlider
              title={"Start"}
              valuePostFix=" kr."
              min={0}
              max={1000000}
              steps={1000000 / 50}
              value={startValue}
              setValue={setStartValue}
              icon={SavingsOutlinedIcon}
            />
            <FBSlider
              title={"Månedlig indskud"}
              valuePostFix=" kr."
              min={0}
              max={10000}
              value={deposit}
              setValue={setDeposit}
              icon={CalendarMonthOutlinedIcon}
            />
            <FBSlider
              title={"Afkast"}
              valuePostFix="%"
              min={0}
              max={10}
              steps={0.1}
              value={interest}
              setValue={setInterest}
              formatter={(value: number) => value.toFixed(1)}
              icon={TrendingUpOutlinedIcon}
            />
            <FBSlider
              title={"Tid"}
              valuePostFix=" år"
              min={0}
              max={40}
              steps={1}
              value={totalYears}
              setValue={setTotalYears}
              icon={ScheduleOutlinedIcon}
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {/* Summary Box */}
              <SummaryBox
                title={`Værdi efter ${calculationValues.totalYears} år`}
                mainValue={`${formatCurrency(finalValue)} kr.`}
                secondaryValues={[
                  {
                    label: "Indskudt",
                    value: `${formatCurrency(totalDeposit)} kr.`,
                    percentage: isNaN(totalDeposit / finalValue)
                      ? 0
                      : (totalDeposit / finalValue) * 100,
                  },
                  {
                    label: "Afkast",
                    value: `${formatCurrency(finalValue - totalDeposit)} kr.`,
                    percentage: isNaN((finalValue - totalDeposit) / totalDeposit)
                      ? 0
                      : ((finalValue - totalDeposit) / finalValue) * 100,
                  },
                ]}
                share={true}
                stateParams={stateParams}
              />

              {/* Graph */}
              <Box>
                <CompoundGraph
                  startValue={calculationValues.startValue}
                  interest={calculationValues.interest}
                  monthlyDeposit={calculationValues.deposit}
                  totalYears={calculationValues.totalYears}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
