import { InflationData } from "../services/BackendTypes";

// Add interface for the return type
interface GrowthResult {
  year: number;
  currentValue: number;
  totalDeposits: number;
}

export function calculateCompoundGrowth(
  startValue: number,
  monthlyDeposit: number,
  interest: number,
  totalYears: number,
  depositLimit?: number
): GrowthResult[] {
  const years = totalYears;
  const yearlyDeposit = monthlyDeposit * 12;
  let totalDeposits = startValue;
  let currentValue = startValue;

  const results = [];

  for (let year = 1; year <= years; year++) {
    if (depositLimit && totalDeposits + yearlyDeposit > depositLimit) {
      if (totalDeposits < depositLimit) {
        const remainingDeposit = depositLimit - totalDeposits;
        totalDeposits += remainingDeposit;
        currentValue += remainingDeposit;
      }
    } else {
      totalDeposits += yearlyDeposit;
      currentValue += yearlyDeposit;
    }

    currentValue *= 1 + interest / 100;
    results.push({
      year,
      currentValue: parseFloat(currentValue.toFixed(0)),
      totalDeposits: parseFloat(totalDeposits.toFixed(0)),
    });
  }

  return results;
}


interface TimeToTargetResult {
  years: number;
  totalDeposits: number;
  totalInterest: number;
  finalAmount: number;
}

export function calculateYearsToTarget(
  startValue: number,
  monthlyDeposit: number,
  interest: number,
  target: number
): TimeToTargetResult {
  if (interest === 0 && monthlyDeposit === 0) {
    return {
      years: Infinity,
      totalDeposits: startValue,
      totalInterest: 0,
      finalAmount: startValue
    };
  }

  if (startValue >= target) {
    return {
      years: 0,
      totalDeposits: startValue,
      totalInterest: 0,
      finalAmount: startValue
    };
  }

  const monthlyInterest = interest / 12 / 100;
  let totalDeposits = startValue;
  let currentValue = startValue;
  let months = 0;

  do {
    months++;
    totalDeposits += monthlyDeposit;
    currentValue += monthlyDeposit;
    currentValue *= 1 + monthlyInterest;

    if (months > 1000 * 12) {
      return {
        years: Infinity,
        totalDeposits: startValue,
        totalInterest: 0,
        finalAmount: currentValue
      };
    }
  } while (currentValue <= target);

  const years = months / 12;
  const totalInterest = currentValue - totalDeposits;

  return {
    years,
    totalDeposits,
    totalInterest,
    finalAmount: currentValue
  };
}

interface InflationResult {
  adjustedAmount: number;
  percentageChange: number;
}

export const calculateInflation = (
  startYear: number,
  endYear: number,
  amount: number,
  inflationData: InflationData[]
): InflationResult => {
  const startPrice = inflationData.find(data => data.year === startYear)?.index_value;
  const endPrice = inflationData.find(data => data.year === endYear)?.index_value;

  if (!startPrice || !endPrice) {
    throw new Error("Inflation data not found for the specified years");
  }

  // Calculate the inflation multiplier
  const multiplier = endPrice / startPrice;
  const adjustedAmount = amount * multiplier;
  const percentageChange = (adjustedAmount / amount - 1) * 100;

  return {
    adjustedAmount: Math.round(adjustedAmount * 100) / 100,
    percentageChange: Math.round(percentageChange * 10) / 10,
  };
};

interface ArpResult {
  yearlyValues: number[];
  yearlyFees: number[];
}

export function calculateArpAdjustedReturn(
  totalYears: number,
  arp: number,
  startValue: number,
  annualReturn: number,
  monthlyDeposit: number = 0
): ArpResult {
  const yearlyValues: number[] = new Array(totalYears + 1);
  const yearlyFees: number[] = new Array(totalYears + 1);

  // Initialize starting values
  yearlyValues[0] = startValue;
  yearlyFees[0] = 0;

  const yearlyDeposit = monthlyDeposit * 12;

  // Calculate yearly values and fees
  for (let year = 1; year <= totalYears; year++) {
    // Add yearly deposit
    yearlyValues[year] = yearlyValues[year - 1] + yearlyDeposit;

    // Calculate growth before ARP
    yearlyValues[year] = yearlyValues[year] * (1 + annualReturn / 100);

    // Calculate ARP fee for this year
    yearlyFees[year] = (yearlyValues[year] * (arp / 100));

    // Subtract ARP fee from value
    yearlyValues[year] = yearlyValues[year] - yearlyFees[year];
  }

  return {
    yearlyValues: yearlyValues.map(value => parseFloat(value.toFixed(0))),
    yearlyFees: yearlyFees.map(fee => parseFloat(fee.toFixed(0)))
  };
}

export interface TaxDividendResult {
  afterTaxDividend: number;
  taxAmountBelow: number;
  taxAmountAbove: number;
}

export function calculateTaxDividend(
  preTaxDividend: number,
  married: boolean,
  progressionLimit: number,
  taxRatesBelow: number,
  taxRatesAbove: number
): TaxDividendResult {
  const actualProgressionLimit = married ? progressionLimit * 2 : progressionLimit;
  const highTax = Math.max(0, preTaxDividend - actualProgressionLimit);
  const lowTax = Math.min(preTaxDividend, actualProgressionLimit);

  const taxAmountBelow = lowTax * taxRatesBelow / 100;
  const taxAmountAbove = highTax * taxRatesAbove / 100;

  const afterTaxDividend = preTaxDividend - taxAmountBelow - taxAmountAbove;

  return {
    afterTaxDividend,
    taxAmountBelow,
    taxAmountAbove
  };
}

export interface RequiredDividendResult {
  requiredInvestment: number;
  yearlyDividend: number;
  yearlyAfterTax: number;
  monthlyAfterTax: number;
  totalTaxPercentage: number;
  aboveTaxPercentage: number;
  taxAmountBelow: number;
  taxAmountAbove: number;
}

export function calculateRequiredDividend(
  monthlyBudget: number,
  dividendYieldPercentage: number,
  married: boolean,
  progressionLimit: number,
  taxRatesBelow: number,
  taxRatesAbove: number
): RequiredDividendResult {
  // Calculate yearly amount needed after tax
  const yearlyAfterTaxNeeded = monthlyBudget * 12;

  // Calculate required pre-tax amount through iteration
  let estimatedPreTax = yearlyAfterTaxNeeded;
  let afterTax = 0;

  // Iteratively find the pre-tax amount that yields desired after-tax amount
  while (Math.abs(afterTax - yearlyAfterTaxNeeded) > 1) {
    const result = calculateTaxDividend(
      estimatedPreTax,
      married,
      progressionLimit,
      taxRatesBelow,
      taxRatesAbove
    );

    afterTax = result.afterTaxDividend;
    estimatedPreTax = estimatedPreTax * (yearlyAfterTaxNeeded / afterTax);
  }

  const finalResult = calculateTaxDividend(
    estimatedPreTax,
    married,
    progressionLimit,
    taxRatesBelow,
    taxRatesAbove
  );

  // Calculate required investment based on dividend yield
  const requiredInvestment = (estimatedPreTax / dividendYieldPercentage) * 100;

  const totalTaxPercentage = (finalResult.taxAmountAbove + finalResult.taxAmountBelow) / estimatedPreTax * 100;
  const aboveTaxPercentage = finalResult.taxAmountAbove / (finalResult.taxAmountAbove + finalResult.taxAmountBelow) * 100;

  return {
    requiredInvestment,
    yearlyDividend: estimatedPreTax,
    yearlyAfterTax: finalResult.afterTaxDividend,
    monthlyAfterTax: finalResult.afterTaxDividend / 12,
    totalTaxPercentage,
    aboveTaxPercentage,
    taxAmountBelow: finalResult.taxAmountBelow,
    taxAmountAbove: finalResult.taxAmountAbove,
  };
}

export interface DebtPaymentResult {
  month: number;
  remainingDebt: number;
  totalPaid: number;
  totalInterestPaid: number;
  totalPrincipalPaid: number;
}

export function calculateDebtRepayment(
  initialDebt: number,
  yearlyInterestRate: number,
  monthlyPayment: number
): DebtPaymentResult[] {
  // Check if payment is too small to ever repay debt at the start
  const monthlyInterestRate = yearlyInterestRate / 12 / 100;
  const minimumPayment = initialDebt * monthlyInterestRate;
  if (monthlyPayment <= minimumPayment) {
    return [{
      month: 0,
      remainingDebt: initialDebt,
      totalPaid: 0,
      totalInterestPaid: 0,
      totalPrincipalPaid: 0
    }];
  }

  let remainingDebt = initialDebt;
  let totalPaid = 0;
  let totalInterestPaid = 0;
  let month = 0;
  const monthlyData: DebtPaymentResult[] = [{
    month,
    remainingDebt: initialDebt,
    totalPaid: 0,
    totalInterestPaid: 0,
    totalPrincipalPaid: 0
  }];

  while (remainingDebt > 0 && month < 1200) {
    month++;

    // Calculate interest for this month
    const monthlyInterest = remainingDebt * monthlyInterestRate;
    totalInterestPaid += monthlyInterest;

    // Calculate actual payment for this month
    const actualPayment = Math.min(monthlyPayment, remainingDebt + monthlyInterest);
    const availableForPrincipal = actualPayment - monthlyInterest;
    const principalPayment = Math.min(remainingDebt, availableForPrincipal);

    remainingDebt -= principalPayment;
    totalPaid += actualPayment;

    // Store monthly data point
    monthlyData.push({
      month,
      remainingDebt: Math.max(0, parseFloat(remainingDebt.toFixed(2))),
      totalPaid: parseFloat(totalPaid.toFixed(2)),
      totalInterestPaid: parseFloat(totalInterestPaid.toFixed(2)),
      totalPrincipalPaid: parseFloat((totalPaid - totalInterestPaid).toFixed(2))
    });

    // Only return if the monthly payment can't cover the interest
    if (monthlyPayment <= monthlyInterest) {
      return monthlyData;
    }
  }

  return monthlyData;
}

export interface RequiredPaymentResult {
  requiredMonthlyPayment: number;
  repaymentSchedule: DebtPaymentResult[];
}

export function calculateRequiredMonthlyPayment(
  initialDebt: number,
  yearlyInterestRate: number,
  numberOfMonths: number
): RequiredPaymentResult {
  const monthlyInterestRate = yearlyInterestRate / 12 / 100;

  // Using the loan payment formula: PMT = P * (r * (1 + r)^n) / ((1 + r)^n - 1)
  // Where: PMT = monthly payment
  //        P = principal (initial debt)
  //        r = monthly interest rate
  //        n = total number of months
  const monthlyPayment = yearlyInterestRate === 0
    ? initialDebt / numberOfMonths
    : initialDebt *
    (monthlyInterestRate * Math.pow(1 + monthlyInterestRate, numberOfMonths)) /
    (Math.pow(1 + monthlyInterestRate, numberOfMonths) - 1);

  // Calculate the full repayment schedule
  const repaymentSchedule = calculateDebtRepayment(
    initialDebt,
    yearlyInterestRate,
    monthlyPayment
  );

  return {
    requiredMonthlyPayment: parseFloat(monthlyPayment.toFixed(0)),
    repaymentSchedule
  };
}