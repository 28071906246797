import { useParams } from "react-router-dom";
import { CompoundCalculator } from "./CompoundCalculator";
import { TimeToFirstMillionCalculator } from "./TimeToFirstMillionCalculator";
import { InflationCalculator } from "./InflationCalculator";
import { ARPCompareCalculator } from "./ARPCompareCalculator";
import CAGRCalculator from "./CAGRCalculator";
import AbisSearchTool from "../Tools/AbisSearchTool";
import BoerneOpsparingDKCalculator from "./BoerneOpsparingDKCalculator";
import IndexDisplay from "../data-displays/IndexDisplay";
import { NationalBankRatesDisplay } from "../data-displays/NationalBankRatesDisplay";
import { FutureInflationCalculator } from "./FutureInflationCalculator";
import { TaxDividendCalculator } from "./TaxDividendCalculator";
import { DividendRequiredCalculator } from "./DividendRequiredCalculator";
import { DebtFreeCalculator } from "./DebtFreeCalculator";

interface CalculatorProps {
  calculatorType?: string;
}

export function Calculator({ calculatorType }: CalculatorProps) {
  const params = useParams();
  const activeCalculator = calculatorType || params.calculatorType;

  const getCalculator = () => {
    const normalizedType = activeCalculator?.replace(/-/g, "_").toUpperCase();

    switch (normalizedType) {
      case "COMPOUND_INTEREST":
        return <CompoundCalculator />;
      case "TIME_TO_FIRST_MILLION":
        return <TimeToFirstMillionCalculator />;
      case "INFLATION":
        return <InflationCalculator />;
      case "ANNUAL_PERCENTAGE_RATE":
        return <ARPCompareCalculator />;
      case "COMPOUND_ANNUAL_GROWTH_RATE":
        return <CAGRCalculator />;
      case "ABIS_LIST_SEARCH":
        return <AbisSearchTool />;
      case "BOERNEOPSPARING_DK":
        return <BoerneOpsparingDKCalculator />;
      case "INDEX_DATA":
        return <IndexDisplay />;
      case "NATIONAL_BANK_RATES":
        return <NationalBankRatesDisplay />;
      case "FUTURE_INFLATION":
        return <FutureInflationCalculator />;
      case "TAX_OF_DIVIDEND":
        return <TaxDividendCalculator />;
      case "BUDGET_OF_DIVIDEND":
        return <DividendRequiredCalculator />;
      case "TIME_TO_DEBT_FREE":
        return <DebtFreeCalculator />;
      default:
        return <div>Calculator type "{activeCalculator}" not found</div>;
    }
  };

  return getCalculator();
}
