import { Box, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useEffect, useState, useRef } from "react";
import { FBSlider } from "../FBSlider";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import TrendingUpOutlinedIcon from "@mui/icons-material/TrendingUpOutlined";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import { calculateDebtRepayment, calculateRequiredMonthlyPayment, DebtPaymentResult } from "../../utils/calculations";
import { formatCurrency } from "../../utils/formatters";
import { SummaryBox } from "./SummaryBox";
import { useSearchParams } from "react-router-dom";
import { DebtFreeGraph } from "./DebtFreeGraph";
import { useFormLogger } from "../../hooks/useFormLogger";

export function DebtFreeCalculator() {
  const [searchParams] = useSearchParams();

  const INITIAL_VALUES = {
    debt: 250000,
    interest: 4.0,
    installment: 2000,
    inputMethod: "installment" as const,
  };

  const [debt, setDebt] = useState<number>(Number(searchParams.get('debt')) || INITIAL_VALUES.debt);
  const [interest, setInterest] = useState<number>(Number(searchParams.get('interest')) || INITIAL_VALUES.interest);
  const [installment, setInstallment] = useState<number>(Number(searchParams.get('installment')) || INITIAL_VALUES.installment);
  const [monthlyData, setMonthlyData] = useState<DebtPaymentResult[]>([]);

  const [inputMethod, setInputMethod] = useState<'installment' | 'time'>(
    (searchParams.get('inputMethod') as 'installment' | 'time') || INITIAL_VALUES.inputMethod
  );

  const [totalMonths, setTotalMonths] = useState<number>(0);

  const formValues = {
    debt,
    interest,
    installment,
    inputMethod
  };

  useFormLogger({
    formName: 'debt_free_calculator',
    values: formValues
  });

  const stateParams = Object.entries({
    debt,
    interest,
    installment,
  }).reduce((acc, [key, value]) => {
    if (value !== INITIAL_VALUES[key as keyof typeof INITIAL_VALUES]) {
      acc[key] = value;
    }
    return acc;
  }, {} as Record<string, number>);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (inputMethod === "installment") {
        const result = calculateDebtRepayment(debt, interest, installment);

        // Check if debt is unpayable (result has only initial entry)
        const isUnpayable = result.length === 1;

        setTotalMonths(isUnpayable ? Infinity : result[result.length - 1].month);
        setMonthlyData(result);
      }
    }, 300);
    return () => clearTimeout(handler);
  }, [debt, installment, interest, inputMethod]);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (inputMethod === "time") {
        const result = calculateRequiredMonthlyPayment(debt, interest, totalMonths);
        setInstallment(result.requiredMonthlyPayment);

        setMonthlyData(result.repaymentSchedule);
      }
    }, 300);
    return () => clearTimeout(handler);
  }, [debt, interest, totalMonths, inputMethod]);

  const handleInputMethodChange = (
    event: React.MouseEvent<HTMLElement>,
    newInputMethod: "installment" | "time" | null,
  ) => {
    if (newInputMethod !== null) {
      setInputMethod(newInputMethod);
    }
  };


  return (
    <Box
      sx={{
        display: "flex",
        my: 2,
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: 1,
          }}
        >
          <Box
            sx={{
              flex: { xs: "1", md: "0 0 400px" },
              display: "flex",
              flexDirection: "column",
            }}
          >
            <FBSlider
              title={"Start restgæld (tryk for indtaste)"}
              valuePostFix=" kr."
              min={0}
              max={1000000}
              steps={1000000 / 50}
              value={debt}
              setValue={setDebt}
              icon={PaidOutlinedIcon}
            />
            <FBSlider
              title={"Rårlig rente (tryk for indtaste)"}
              valuePostFix=" %"
              min={0}
              max={20}
              steps={0.1}
              value={interest}
              setValue={setInterest}
              icon={TrendingUpOutlinedIcon}
            />
            <ToggleButtonGroup
              value={inputMethod}
              exclusive
              onChange={handleInputMethodChange}
              sx={{
                marginTop: 1,
                width: "100%",
                display: 'flex',
                "& .MuiToggleButton-root": {
                  flex: 1,
                },
                "& .MuiToggleButton-root:not(.Mui-selected)": {
                  color: theme => theme.palette.secondary.main
                },
                "& .MuiToggleButton-root.Mui-selected": {
                  backgroundColor: theme => theme.palette.primary.main,
                  color: theme => theme.palette.primary.contrastText,
                  "&:hover": {
                    backgroundColor: theme => theme.palette.primary.dark,
                  }
                },
              }}
            >
              <ToggleButton value="installment" sx={{ boxShadow: 3 }}>Afdrag pr. måned</ToggleButton>
              <ToggleButton value="time" sx={{ boxShadow: 3 }}>Antal måneder</ToggleButton>
            </ToggleButtonGroup>
            {inputMethod === "installment" && (
              <FBSlider
                title={"Afdrag pr. måned (tryk for indtaste)"}
                valuePostFix=" kr."
                min={0}
                max={10000}
                steps={50}
                value={installment}
                setValue={setInstallment}
                icon={CalendarMonthOutlinedIcon}
              />
            )}
            {inputMethod === "time" && (
              <FBSlider
                title={"Antal måneder (tryk for indtaste)"}
                valuePostFix="  måneder."
                min={1}
                max={12 * 30}
                steps={1}
                value={totalMonths}
                setValue={setTotalMonths}
                icon={CalendarMonthOutlinedIcon}
              />
            )}
          </Box>
          <Box sx={{ flex: 1 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {/* Summary Box */}
              <SummaryBox
                title={inputMethod === 'time' ? 'Påkrævet månedligt afdrag' : 'Tid til gældsfrihed'}
                mainValue={inputMethod === 'time'
                  ? `${formatCurrency(installment)} kr.`
                  : totalMonths === Infinity
                    ? "Aldrig :("
                    : totalMonths >= 12
                      ? `${Math.floor(totalMonths / 12)} år og ${totalMonths % 12} måneder`
                      : `${totalMonths} måneder`}
                mainSubtitle={inputMethod === 'time'
                  ? ``
                  : totalMonths === Infinity
                    ? "Afdraget er for lavt"
                    : `(${totalMonths} måneder)`}
                secondaryValues={[
                  {
                    label: "Betalt i alt",
                    value: monthlyData.length
                      ? `${formatCurrency(monthlyData[monthlyData.length - 1].totalPaid)} kr.`
                      : "0 kr.",
                  },
                  {
                    label: "Renter i alt",
                    value: monthlyData.length
                      ? `${formatCurrency(monthlyData[monthlyData.length - 1].totalInterestPaid)} kr.`
                      : "0 kr.",
                  },
                ]}
                share={true}
                stateParams={stateParams}
              />

              {/* Graph */}
              <Box>
                <DebtFreeGraph data={monthlyData} />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box >
  );
}
