import { Box, Card, CardContent, useTheme, useMediaQuery } from "@mui/material";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { formatCurrency } from "../../utils/formatters";

interface TaxDividendGraphProps {
  taxAmountBelow: number;
  taxAmountAbove: number;
  afterTaxDividend: number;
  taxPercentageBelow: number;
  taxPercentageAbove: number;
  height: number;
}

export function TaxDividendGraph({
  taxAmountBelow,
  taxAmountAbove,
  afterTaxDividend,
  taxPercentageBelow,
  taxPercentageAbove,
  height
}: TaxDividendGraphProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const showSeparateBars = height > 200 && !isMobile;

  const roundToThousand = (num: number) => Math.round(num / 1000) * 1000;

  const data = [{
    name: 'Udbytte fordeling',
    'Efter skat': Math.round(afterTaxDividend),
    [`Skat over ${isMobile ? 'grænsen' : 'progressionsgrænse'} (${taxPercentageAbove}%)`]: Math.round(taxAmountAbove),
    [`Skat under ${isMobile ? 'grænsen' : 'progressionsgrænse'} (${taxPercentageBelow}%)`]: Math.round(taxAmountBelow),
  }];

  return (
    <Card sx={{ mt: 1 }}>
      <CardContent>
        <Box sx={{ width: '100%', height: isMobile ? 180 : height }}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              layout="vertical"
              data={data}
              margin={showSeparateBars
                ? { top: 20, right: 30, left: 20, bottom: 20 }
                : { top: 5, right: 30, left: 20, bottom: 5 }
              }
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                type="number"
                domain={[0, roundToThousand(showSeparateBars ? afterTaxDividend : afterTaxDividend + taxAmountBelow + taxAmountAbove)]}
                tickFormatter={(value) => roundToThousand(value).toString()}
              />
              <YAxis type="category" dataKey="name" hide />
              <Tooltip
                formatter={(value: number, name: string) => [
                  `${formatCurrency(value)} kr.`,
                  name
                ]}
              />
              <Legend layout={showSeparateBars ? "vertical" : "horizontal"} />
              <Bar
                dataKey="Efter skat"
                stackId={showSeparateBars ? undefined : "a"}
                fill={theme.palette.primary.main}
              />
              <Bar
                dataKey={`Skat over ${isMobile ? 'grænsen' : 'progressionsgrænse'} (${taxPercentageAbove}%)`}
                stackId={showSeparateBars ? undefined : "a"}
                fill={theme.palette.error.main}
              />
              <Bar
                dataKey={`Skat under ${isMobile ? 'grænsen' : 'progressionsgrænse'} (${taxPercentageBelow}%)`}
                stackId={showSeparateBars ? undefined : "a"}
                fill={theme.palette.warning.main}
              />
            </BarChart>
          </ResponsiveContainer>
        </Box>
      </CardContent>
    </Card>
  );
} 