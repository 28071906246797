import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Box, Card, useTheme } from '@mui/material';
import { formatCurrency } from '../../utils/formatters';

interface DebtFreeGraphProps {
  data: {
    month: number;
    remainingDebt: number;
    totalPaid: number;
    totalInterestPaid: number;
    totalPrincipalPaid: number;
  }[];
}

export function DebtFreeGraph({ data }: DebtFreeGraphProps) {
  const theme = useTheme();

  return (
    <Box>
      <Box
        sx={{
          width: '100%',
          position: 'relative',
          paddingTop: 1,
          height: '100%'
        }}
      >
        <Card sx={{
          width: '100%',
          height: "288px"
        }}>
          <Box sx={{
            width: '100%',
            height: '100%',
          }}>
            <ResponsiveContainer width="100%" height="100%">
              <LineChart
                data={data}
                margin={{
                  top: 20,
                  right: 30,
                  left: 40,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="month"
                  tickFormatter={(month) => `${Math.floor(month / 12)} år ${month % 12} mdr`}
                />
                <YAxis
                  tickFormatter={(value) => `${formatCurrency(value)}\u00A0kr`}
                  domain={[0, (dataMax: number) => Math.ceil(dataMax / 1000) * 1000]}
                />
                <Tooltip
                  formatter={(value: number, name: string) => [
                    `${formatCurrency(value)} kr`,
                    name
                  ]}
                  labelFormatter={(month) => {
                    const months = month % 12;
                    return `${Math.floor(month / 12)} år og ${months} ${months === 1 ? 'måned' : 'måneder'}`;
                  }}
                />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="remainingDebt"
                  name="Restgæld"
                  stroke={theme.palette.primary.main}
                  strokeWidth={2}
                  dot={false}
                />
                <Line
                  type="monotone"
                  dataKey="totalInterestPaid"
                  name="Renter i alt"
                  stroke={theme.palette.error.main}
                  strokeWidth={2}
                  dot={false}
                />
                <Line
                  type="monotone"
                  dataKey="totalPaid"
                  name="Betalt i alt"
                  stroke={theme.palette.warning.main}
                  strokeWidth={2}
                  dot={false}
                />
              </LineChart>
            </ResponsiveContainer>
          </Box>
        </Card>
      </Box>
    </Box>
  );
} 