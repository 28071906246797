import { Box, Typography, useTheme } from "@mui/material";
import { useEffect, useState, useRef, useMemo } from "react";
import { FBSlider } from "../FBSlider";
import SavingsOutlinedIcon from "@mui/icons-material/SavingsOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { calculateTaxDividend, TaxDividendResult } from "../../utils/calculations";
import { formatCurrency } from "../../utils/formatters";
import { SummaryBox } from "./SummaryBox";
import { useSearchParams } from "react-router-dom";
import { FBSwitchField } from "../FBSwitchField";
import PeopleIcon from '@mui/icons-material/People';
import { useFetchLatestUpdatedAt, useFetchTaxRates } from "../../services/BackendService"
import { CalculatorSkeleton } from "../skeletons/CalculatorSkeleton";
import PercentIcon from '@mui/icons-material/Percent';
import PaidIcon from '@mui/icons-material/Paid';
import { TaxDividendGraph } from "./TaxDividendGraph";

export function TaxDividendCalculator() {

  const [searchParams] = useSearchParams();
  const { data: taxRates, isLoading } = useFetchTaxRates();
  const { data: latestUpdatedAt, isLoading: isLoadingLatestUpdatedAt } = useFetchLatestUpdatedAt("skat_rates");

  const INITIAL_VALUES = {
    preTaxDividend: 100000,
    married: false,
  };

  const theme = useTheme();

  const [year, setYear] = useState<number>(0);
  const [preTaxDividend, setPreTaxDividend] = useState<number>(Number(searchParams.get('preTaxDividend')) || INITIAL_VALUES.preTaxDividend);
  const [married, setMarried] = useState<boolean>(Boolean(searchParams.get('married')) || INITIAL_VALUES.married);
  const [progressionLimit, setProgressionLimit] = useState<number>(0);
  const [taxRatesBelow, setTaxRatesBelow] = useState<number>(0);
  const [taxRatesAbove, setTaxRatesAbove] = useState<number>(0);

  const [taxDividend, setTaxDividend] = useState<TaxDividendResult & {
    totalTaxPercentage: number;
    aboveTaxPercentage: number;
  }>({
    afterTaxDividend: 0,
    taxAmountBelow: 0,
    taxAmountAbove: 0,
    totalTaxPercentage: 0,
    aboveTaxPercentage: 0
  });

  useEffect(() => {
    if (!taxRates?.length) return;

    // Get available years and set initial year if not set
    const availableYears = [...new Set(taxRates.map(rate => rate.year))].sort((a, b) => b - a);
    if (year === 0) {
      setYear(availableYears[0]);
      return;
    }

    // Filter rates for selected year
    const yearRates = taxRates.filter(rate => rate.year === year);

    // Set progression limit, skat_id 1472 is the progression limit
    const progressionRate = yearRates.find(rate => rate.skat_id === "1472")?.value ?? 0;
    if (married) {
      setProgressionLimit(progressionRate * 2);
    } else {
      setProgressionLimit(progressionRate);
    }

    // Set tax rates below
    const belowRate = yearRates.find(rate => rate.skat_id === "6820_under")?.value ?? 0;
    setTaxRatesBelow(belowRate);

    // Set tax rates above
    const aboveRate = yearRates.find(rate => rate.skat_id === "6820_over")?.value ?? 0;
    setTaxRatesAbove(aboveRate);
  }, [taxRates, year, married]);

  useEffect(() => {
    const handler = setTimeout(() => {
      const { afterTaxDividend, taxAmountBelow, taxAmountAbove } = calculateTaxDividend(
        preTaxDividend,
        married,
        progressionLimit,
        taxRatesBelow,
        taxRatesAbove
      );

      const totalTaxPercentage = (taxAmountAbove + taxAmountBelow) / preTaxDividend * 100;
      const aboveTaxPercentage = taxAmountAbove / (taxAmountAbove + taxAmountBelow) * 100;

      setTaxDividend({
        afterTaxDividend,
        taxAmountBelow,
        taxAmountAbove,
        totalTaxPercentage,
        aboveTaxPercentage
      });
    }, 300);

    return () => clearTimeout(handler);
  }, [preTaxDividend, married, progressionLimit, taxRatesBelow, taxRatesAbove]);


  if (isLoading || isLoadingLatestUpdatedAt) {
    return <CalculatorSkeleton maxWidth="100%" inputFields={3} graphHeight={232} />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        my: 2,
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: 1,
          }}
        >
          <Box
            sx={{
              flex: { xs: "1", md: "0 0 400px" },
              display: "flex",
              flexDirection: "column",
            }}
          >
            <FBSlider
              title={"Udbytte før skat"}
              valuePostFix=" kr."
              min={0}
              max={1000000}
              steps={1000}
              value={preTaxDividend}
              setValue={setPreTaxDividend}
              icon={PaidIcon}
            />
            <FBSwitchField
              title="Gift"
              value={married}
              setValue={setMarried}
              textValue="Gift"
              icon={PeopleIcon}
              iconColor={theme.palette.primary.main}
            />
            <FBSlider
              title={"Indkomstår"}
              min={taxRates ? Math.min(...taxRates.map(rate => rate.year)) : 2024}
              max={taxRates ? Math.max(...taxRates.map(rate => rate.year)) : 2024}
              steps={1}
              value={year}
              setValue={setYear}
              icon={CalendarMonthOutlinedIcon}
              editable={false}
              formatter={(value) => value.toString()}
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {/* Summary Box */}
              <SummaryBox
                title={`Udbytte efter skat`}
                mainValue={`${formatCurrency(taxDividend.afterTaxDividend)} kr.`}
                mainSubtitle={`(${(100 - taxDividend.totalTaxPercentage).toFixed(1)}% tilbage)`}
                secondaryValues={[
                  {
                    label: "Total skat",
                    value: `${formatCurrency(taxDividend.taxAmountAbove + taxDividend.taxAmountBelow)} kr.`,
                    percentage: taxDividend.totalTaxPercentage,
                    percentageLabel: `af udbyttet`
                  },
                  {
                    label: "Skat over progressionsgrænse",
                    value: `${formatCurrency(taxDividend.taxAmountAbove)} kr.`,
                    percentage: taxDividend.aboveTaxPercentage,
                    percentageLabel: `af skatten`
                  },
                ]}
                share={false}
              />

              <Box>
                <TaxDividendGraph
                  taxAmountBelow={taxDividend.taxAmountBelow}
                  taxAmountAbove={taxDividend.taxAmountAbove}
                  afterTaxDividend={taxDividend.afterTaxDividend}
                  taxPercentageBelow={taxRatesBelow}
                  taxPercentageAbove={taxRatesAbove}
                  height={136}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={{
          display: "flex", gap: 1,
          flexDirection: { xs: "column", md: "row" },


        }}>
          <Box sx={{ flex: { xs: "1", md: "0 0 400px" } }}>
            <FBSlider
              title={"Progressionsgrænse"}
              valuePostFix=" kr."
              min={0}
              max={200000}
              steps={500}
              value={progressionLimit}
              setValue={setProgressionLimit}
              icon={PaidIcon}
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <FBSlider
              title={"Skat under Progressionsgrænse"}
              valuePostFix=" %"
              min={0}
              max={50}
              steps={1}
              value={taxRatesBelow}
              setValue={setTaxRatesBelow}
              icon={PercentIcon}
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <FBSlider
              title={"Skat over Progressionsgrænse"}
              valuePostFix=" %"
              min={0}
              max={50}
              steps={1}
              value={taxRatesAbove}
              setValue={setTaxRatesAbove}
              icon={PercentIcon}
            />
          </Box>
        </Box>
        <Box>
          {latestUpdatedAt && (
            <Typography variant="subtitle2" fontWeight={200} fontSize={12} paddingTop={1.5}>
              (Tal for progressionsgrænse og skatte procenter er for {taxRates?.[0]?.year} og er sidst opdateret i {
                new Intl.DateTimeFormat('da-DK', {
                  month: 'long',
                  year: 'numeric'
                }).format(new Date(latestUpdatedAt.last_updated_at))
              } fra <u><a href="https://skat.dk/hjaelp/satser" target="_blank" rel="noopener noreferrer">skat.dk</a></u>)
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
}
