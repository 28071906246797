import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import { useFetchArticlesCards } from "../services/StrapiService";
import { getImageProps } from "../services/StrapiHelpers";
import dayjs from 'dayjs';
import 'dayjs/locale/da';
import Masonry from '@mui/lab/Masonry';

export default function ArticlesPage() {
  const fetchArticlesCards = useFetchArticlesCards();

  if (fetchArticlesCards.isLoading || !fetchArticlesCards.data) {
    return null;
  }

  return (
    <Box sx={{ maxWidth: { xs: "100%" }, margin: "0 auto", py: 4 }}>
      <Masonry
        columns={{ xs: 1, md: 2 }}
        spacing={4}
      >
        {fetchArticlesCards.data[0].articles.map((article) => (
          <Box
            component="a"
            href={`/articles/${article.slug}`}
            sx={{
              textDecoration: 'none',
              color: 'inherit',
              display: 'block',
            }}
            key={article.id}
          >
            <Card
              component="article"
              sx={{
                width: '100%',
                boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.1)",
                borderRadius: 2,
                overflow: "hidden",
                transition: "all 0.3s ease",
                transform: "scale(1)",
                "&:hover": {
                  boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.2)",
                  transform: "scale(1.02)",
                },
              }}
            >
              <CardContent>
                {article.coverImage && (
                  <Box
                    component="img"
                    {...getImageProps(article.linkImage, "medium", true)}
                    alt={article.title}
                    sx={{
                      maxHeight: 300,
                      width: 'auto',
                      objectFit: 'contain',
                      mb: 2,
                      borderRadius: 1,
                      display: 'block',
                      margin: '0 auto'
                    }}
                  />
                )}
                <Box>
                  <Typography variant="h4" color="primary">{article.title}</Typography>
                </Box>
                {article.showAuthors && article.authors.length > 0 && (
                  <>
                    <Box>
                      <Typography variant="body2" color="text.secondary">Skrevet af {article.authors.map((author) => author.name).join(", ")}</Typography>
                    </Box>
                    <Box>
                      <Typography variant="body2" color="text.secondary">Udgivet {dayjs(article.createdAt).locale('da').format('D. MMMM YYYY')}</Typography>
                    </Box>
                  </>
                )}
                <Box sx={{ py: 2 }}>
                  <Typography variant="body2" color="text.secondary">{article.description}</Typography>
                </Box>
                <Button variant="contained" color="primary">Læs mere</Button>
              </CardContent>
            </Card>
          </Box>
        ))}
      </Masonry>
    </Box>
  );
}
